@use './../../assets/css-vars/breakpoints' as breakpoints;
#footer-a {
    max-width: 80%;
    margin: 30px auto 0px auto;    
    @media screen and (max-width: breakpoints.$md) {
        max-width: 100%;  
    }
    a, a:link {
        text-decoration:none;
        font-weight:400;
        display:block
    }

    h4 {
        font-style: normal;
        font-size: 12px;
        line-height: 18px;
        color: #fff;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        column-gap: 10px;
        img {
            height: 20px;
        }
    }

    .foot-nav-main {
        clear:both;
        .logo {
            margin: 20px 0;
        }
        .foot-nav {
            line-height:24px;
            display: flex;
            justify-content: flex-start;
            height: 100%;
            align-items: flex-start;
            flex-direction: column;
            a {
                white-space: nowrap;
                color: #fff;
                font-size: 12px;
                margin-left: 30px;
            }
        }
        @media screen and (max-width: 575px){ 
            .foot-nav {
                justify-content: center;
            }
        }
    } 
    .foot-bottom {
        padding:15px 0;
        .foot_bottom_links {
            display: flex;
            justify-content: center;
            column-gap: 30px;
            margin: 10px 0 0 0;
            a {
                color: #fff;
                font-size: 12px;
                &:before {
                    content: "|";
                    margin-right: 30px;
                }
                &:first-child:before {
                    display: none;
                }
            }
        }
        .copyright {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;
            color: #fff;
            margin: 0;
            font-size: 12px;
        }
    }
}

@media (max-width:767px) {
    #footer-a {
        .foot-nav-main {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
}

@media screen and (max-width: breakpoints.$sm) {
    .foot_bottom_links {
        column-gap: 10px !important;
        a:before {
            margin-right: 10px !important;
        }
    }
}

.socialMediaSection {
    //border: 1px solid red;
    padding: 10px 0;
    text-align: center;

    div {
        //border: 1px solid green;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    &Followus {
        //border: 1px solid blue;
        display: flex;
        align-items: center;
        font-size: 15px;
        //font-weight: bold;

        img {
            margin-right: 8px;
            width: 15px;
        }
    }

    a {
        //border: 1px solid yellow;
        display: flex;
        align-items: flex-end;

        #instagram_img {
            width: 40px;
        }

        img {
            width: 25px;
        }
    }
}