@use '@/assets/css-vars/breakpoints' as breakpoints;

/* General Styles */
.tabbedMenuPanelContainer {
    background: #FFFFFF;
    display: flex;
    flex-direction: row;
    padding: 10px;
    //width: 100%;
    //max-width: 100%;
    flex: 1, 1, 100%;

    @media screen and (max-width: breakpoints.$dsk) {
        padding: 0;
        //border-top: 0px solid white;
    }
}

.tabsContainer {
    width: 15%;
    min-width: 10%;
    display: flex;
    align-items: center;
    //box-shadow: 0 0 5px 2px rgba(220, 260, 230, 0.5); 
    border-right : 1px solid rgba(220, 260, 230, 1);
    font-weight: bold;
    text-wrap: wrap;
    padding-left: 10px;
    padding-top: 20px;
    padding-right: 5px;
    // not using max-width to 768px to exclude tabs
    @media screen and (max-width: breakpoints.$md) {
        width: 30%;
        min-width: 30%;
        padding-left: 5px;
    }
}

.tab {
    width: 100%;
    font-size: 12px;
    //border: 1px solid blue;
    justify-content: left;
    text-align: left;
    text-transform: none;
    &:hover {
        color: teal;
        //border-bottom: 2px solid teal;
    }
}

.MuiButtonBase-root-MuiTab-root {
    min-height: 25px !important;
}

.tabIcon {
    color: teal;
    margin-left: 1px !important;
}

.showIcon {
    display: block;
}

.hideIcon {
    display: none;
}

.tabPanel {
    width: 100%;
    //min-width: 80%;
    //border: 1px solid green;
}

.gridContainer {
    overflow-y: scroll;
    overflow-x: hidden;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    //border: 1px solid red;
}

.gridItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: none;
    padding: 10px; // Adjust padding as needed
    //border: 1px solid blue;
  }

.itemBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    //border: 1px solid black;
}

.itemIcon {
    position: relative;
    background: #FFFFFF no-repeat center center;
    //border-radius: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    //border: 1px solid red;
    width: 100%;
}

.itemImage {
    width: 95%; 
    height: calc(100% - 20px); 
    background: no-repeat center center;
    &:hover {
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    }
  }
.overlayText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    text-transform: none;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.itemLabel {
    width: 100%;
    font-size: 14px;
    text-align: center;
    margin-top: 2px;
    text-transform: none;
    //border: 1px solid green;
}

.columnItem {
     a {
        text-decoration: none;
        text-transform: none;
        display: flex;
        
        .itemBox {
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            //border: 1px solid red;
        }

        .itemLabel {
            display: flex;
            align-items: left;
            text-align: left;
            transition: color 0.3s ease;
            text-transform: "none";
            @media screen and (min-width: 768px) {
                padding-top: 10px;                
            }
        }

        .headerItem {
            color: teal;
            font-weight: bold;
            align-items: center;
            border-bottom: 1px solid  rgb(175, 233, 233) ;
            padding-top: 10px;
        }

        .itemImage {
            width: 20px; // Adjust width as needed
            height: 20px; // Adjust height as needed
            margin-right: 8px; // Adjust margin as needed
        }

        .itemText {
            display: flex;
            align-items: left;
            text-align: left;
            font-size: 14px;
            text-wrap: wrap;


            &:hover {
                color: teal; // Adjust hover color as needed
            }

        }

        .newSuperscript {
            font-size: 0.90em;
            color: red; // Adjust color as needed
            margin-left: 3px; // Adjust margin as needed
            //margin-top: -4px;
        }
    }
}