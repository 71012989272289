@use './../../assets/css-vars/breakpoints' as breakpoints;

.logo_icon {
    img {
        vertical-align: top;
    }

    .blue1 {
        position: absolute;
    }

    .blue1 img,
    .blue3 img,
    .blue4 img {
        height: 31.969px;
    }

    .blue2 img,
    .blue5 img {
        height: 5px;
    }

    .nestingale {
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 3px;
        left: 18px;
        position: absolute;
        top: 0px;
        left: 35px;
    }
}

.lock-up {
    position: absolute;
    top: 5px;
}

.logo {
    //margin: 20px 0;

    img {
        max-width: 150px;
        height: 60px;
    }

    a {
        display: flex;
        justify-content: center;
    }

    &.footer_logo {
        margin: 20px 0;
    }

    display: flex;
    align-items: center;

    >div {
        display: flex;
        align-items: center;
        min-width: 200px;
    }

    img {
        display: initial;
    }

    .brand_logo {
        margin-left: 15px;
        border-left: solid 1px #A0A4A8;
        padding-left: 10px;

        img {
            width: auto;
        }
    }

    .small-logo {
        max-height: inherit;
    }
}

.builderLogo {
    margin: 10px 20px;

    img {
        max-width: 200px;
    }

    a {
        display: flex;
    }

    &.footer_logo {
        margin: 10px 0;
    }

    display: flex;

    @media screen and (max-width: 768px) {
        justify-content: center;
    }

    >div {
        display: flex;
        align-items: center;
        min-width: 200px;
    }

    img {
        display: initial;
        max-height: 35px;
    }

    .brand_logo {
        margin-left: 15px;
        border-left: solid 1px #A0A4A8;
        padding-left: 10px;

        img {
            max-height: 80px;
            width: auto;
        }
    }

    .small-logo {
        max-height: 40px;
    }
}


@media screen and (max-width: 767px) {
    .logo {
        //margin: 10px 0;
    }
}

@media screen and (max-width: 575px) {
    .logo.footer_logo {
        justify-content: center;
        padding-bottom: 10px;
        margin: 0;

        >div {
            text-align: center;
        }
    }

    .foot-nav {
        justify-content: center;
    }
}

// @media screen and (max-width: breakpoints.$md) {
//     .logo:not(.footer_logo) img {
//         width: 70%;
//     }
// }

// @media screen and (max-width: breakpoints.$sm) {
//     .logo:not(.footer_logo) img {
//         width: 60%;
//     }
// }