.heroTileMainContainer {
    height: 307px;
    width: 554px;
}

.imageAndContentContainer {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.roomImage {
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    height: 250px;
    width: 250px;
}

.title {
    font-family: 'Lora';
    font-size: 28px;
    font-style: italic;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    text-underline-position: 'from-font';
    text-decoration-skip-ink: none;
}

.description {
    font-family: 'Nunito';
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    text-underline-position: 'from-font';
    text-decoration-skip-ink: none;
    color: black;
}

.linkButton {
    font-size: 20px;
    font-weight: 700;
    line-height: 30.01px;
    text-align: center;
    width: 236px;
    height: 53px;
    background-color: #FFFFFF;
    color: #FE6526;
    border-radius: 999px;
    text-transform: none;
    border: 4px solid #FE6526;

    &:hover {
        background-color: #FFFFFF;
        color: #FE6526;
    }
}

// @media (max-width: 600px) {
//     .imageAndContentContainer{
//         display: flex;
//         flex-direction: column;
//         gap: 10px;
//         align-items: center;
//         justify-content: center;
//         text-align: center;
//         }
//     .roomImage{
//         border-radius: 50%;
//         background-size: cover;
//         background-position: center;
//         height: 50px;
//         width:50px;
//         }
//         .heroTileMainContainer{
//             height: 200px;
//             width: 100%;
//             }
//             .title{
//                 font-family: 'Lora';
//                 font-size: 20px;
//                 font-style: italic;
//                 font-weight: 400;
//                 line-height: 36px;
//                 text-align: left;
//                 text-underline-position: 'from-font';
//                 text-decoration-skip-ink: none;
//                 }
//                 .description{
//                 font-family: 'Nunito';
//                 font-size: 20px;
//                 font-weight: 600;
//                 line-height: 30px;
//                 text-align: left;
//                 text-underline-position: 'from-font';
//                 text-decoration-skip-ink:none ;
//                 color: black;
//                 }
//                 .linkButton{
//                 font-size: 20px;
//                 font-weight: 700;
//                 line-height: 30.01px;
//                 text-align: center;
//                 width: 236px;
//                 height: 53px;
//                 background-color: #FFFFFF;
//                 color: #FE6526;
//                 border-radius: 999px;
//                 text-transform: none;
//                 border: 4px solid #FE6526;
//                 &:hover{
//                     background-color: #FFFFFF;
//                     color: #FE6526;
//                 }
//                 }
//     }
////
/// 
//new css
.heroTileBackGroundContainer {
    //
}

.heroTileImageAndTextBackGroundContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
}

.imagesize {

    border-radius: 50%;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    height: 210px;
    width: 210px;

}

.heroTiletextContainer {
    width: 220px;
}

.heroTileTitle {
    font-family: 'Lora';
    font-size: 25px;
    font-weight: 400;
    font-style: italic;
    line-height: 34px;
    text-align: left;
    margin-bottom: 5px;
}

.heroTileDescription {
    font-family: Nunito;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: black;
}

.heroTileButtonContainer {
    text-align: center;
    padding-left: 90px;
}

.ctaButton {
    padding: 0;
    font-size: 19px;
    font-weight: 700;
    width: 226px;
    height: 50px;
    background-color: #FFFFFF;
    color: #FE6526;
    border-radius: 999px;
    text-transform: none;
    border: 4px solid #FE6526;
}

.ctaButton:hover {
    background-color: #FFFFFF;
    color: #FE6526;
}

@media (max-width: 1024px) {
    .imagesize {
        width: 160px;
        height: 160px;
    }

    .heroTiletextContainer {
        width: 100%;
    }

    .heroTileTitle {
        font-size: 23px;
        line-height: 32px;
    }

    .heroTileDescription {
        font-size: 19px;
    }

    .ctaButton {
        width: 200px;
        height: 50px;
        font-size: 16px;
    }

    .heroTileButtonContainer {
        text-align: center;
        padding-left: 0;
    }
}
@media (max-width: 800px) {
    .imagesize {
        height: 120px;
        width: 120px;
    }

    .heroTileTitle {
        font-size: 20px;
        line-height: 24px;
    }

    .heroTileDescription {
        font-size: 16px;
        line-height: 20px;
    }

    .ctaButton {
        width: 160px;
        height: 45px;
        font-size: 13px;
        border: 3px solid #FE6526;
    }
}
@media (max-width: 600px) {
    .heroTileImageAndTextBackGroundContainer {
        // display: flex;
        // justify-content: center;
        // align-items: center;
        column-gap: 10px;
    }

    .imagesize {
        height: 120px;
        width: 120px;
    }

    .heroTileTitle {
        font-size: 20px;
        line-height: 26px;
    }

    .heroTileDescription {
        font-size: 18px;
        line-height: 23px;
    }

    .ctaButton {
        width: 170px;
        height: 48px;
        font-size: 14px;
        font-weight: 600;
        border: 3px solid #FE6526;
    }
}