.heroSectionBackgroundContainer {
  position: relative;
  // height: calc(100vh - 80px);
  height: 704px;
  background-image: url('../../assets//images/image\ 1003.png');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.topCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  width: 528px;
  height: 261px;
  border-radius: 0 0 700px 700px;
  background-image: linear-gradient(#DE9444B2, #78502559);
}

.topCircleContent {
  font-family: 'Lora', 'serif';
  font-size: 42px;
  color: #FFFFFF;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0.2px;
  text-align: center;
  text-underline-position: 'from-font';
  text-decoration-skip-ink: none;

}

.getStartedButton {
  font-size: 16.5px;
  font-weight: 700;
  line-height: 22.5075px;
  letter-spacing: 0.02em;
  text-align: center;
  background-color: #FE6526;
  color: white;
  border-radius: 999px;
  text-transform: none;
  border: 3px solid white;
  width: 205.3125px;
  height: 52.08px;
  margin-bottom: 15px;

  &:hover {
    background-color: #FE6526; /* Prevents color change on hover */
  }
}


.bottomCircle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  padding-top: 20px;
  left: 50%;
  transform: translate(-50%);
  width: 528px;
  height: 261px;
  border-radius: 700px 700px 0 0;
  background-image: linear-gradient(#FFE7D099, #998A7D99);
}

.learnMoreButton {
  font-size: 16.5px;
  font-weight: 700;
  line-height: 22.5075px;
  letter-spacing: 0.02em;
  text-align: center;
  width: 205.3125px;
  height: 52.08px;
  background-color: #FFFFFF;
  color: #FE6526;
  border-radius: 999px;
  text-transform: none;
  border: 3px solid #FE6526;

  &:hover {
    background-color: #FFFFFF; /* Prevents background color change */
    color: #FE6526;           /* Prevents text color change */
  }
}


.topCircleContentContainer {
  width: 525.36px;
}

@media (max-width: 600px) {
  .heroSectionBackgroundContainer {
    height: 535px;
  }

  .topCircle {
    width: 360px;
    height: 180px;
  }

  .topCircleContent {
    margin-top: 0;
    font-size: 30px;
    line-height: 35px;
  }

  .getStartedButton {
    width: 212px;
    height: 48px;
    margin-bottom: 15px;
  }

  .bottomCircle {
    width: 360px;
    height: 180px;
  }

  .learnMoreButton {
    width: 212px;
    height: 48px;
  }
}