/* Add a favorite icon at the top-right corner of the design item */
.favorite-icon {
	position: absolute;
	top: 7px;
	right: 7px;
	z-index: 1000 !important;
	cursor: pointer;
	color: #b0b0b0; /* Heart color */
	size: 20px;
	display: block !important;
	visibility: visible !important;
  }