@use './../../assets/css-vars/breakpoints' as breakpoints;
@import './../../assets/css-vars/common';

.headerSectionWrapper {
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
    background: #fff;
	//border: 1px solid red;
    z-index: 1200;
}

.promo_banner {
	width: 100%;
	padding: 5px;
	background: #2D3137;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	column-gap: 10px;
	z-index: 1201;
	text-align: center;
	flex-wrap: wrap;
	flex-direction: row;

	p {
		color: #fff;
	}

	a {
		color: #1ec2bf;
		text-wrap: nowrap;

		&:hover {
			color: #1ec2bf;
			text-decoration: underline;
		}
	}
}

.headsection {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	column-gap: 20px;
    //height: 100px;
    width: calc(100% - 96px);
	//border: 1px solid blue;
	&:before {
		content: "";
		width: 100%;
		//height: 100px;
		background: #fff;
		position: absolute;
		left: -100%;
	}
	&:after {
		content: "";
		width: 100%;
		//height: 100px;
		background: #fff;
		position: absolute;
		right: -100%;
	}

	.logosection {
		display: flex;
		align-items: center;
		gap: 30px;
		//border: 1px solid yellow;
	}

	.middle_section {
		display: flex;
		flex-direction: column;
		align-items: center;
		//border: 1px solid yellow;
		//height: 100px;
		flex-grow: 1;
		overflow: hidden;

		@media screen and (max-width: breakpoints.$dsk) {
			display: none;
		}
	}

	.login__section {
		margin-left: auto;
		display: flex;
		column-gap: 15px;
		align-items: center;
		font-weight: 600;
		height: 35px;
		margin-top:15px;
		//border: 1px solid yellow;
	}

	@media screen and (max-width: breakpoints.$lg) {
		.heading_text {
			margin-bottom: 32px;
		}


		.logosection {
			flex-grow: 1;
			align-items: center;
			flex-direction: column;
			gap: 10px
		}

		.login__section {
			height: 50px;
		}
		
	}

	@media screen and (max-width: breakpoints.$md) {
		//position: fixed;
		left: 0;
		z-index: 1200;
		background: #fff;
		width: 100%;
		padding: 0 12px;
		
	}

	@media screen and (max-width: breakpoints.$sm) {

	}

	.signin_section {
		display: flex;
		flex-direction: column;
		align-items: center;

		svg {
			color: #25282B;
			font-size: 22px;
		}
	}
}

.header_space {
	height: 10px;
}

.vendor_logo_section {
	position: absolute;
	transform: translateX(-100px);
	left: 50%;

	img {
		max-width: 300px;
		margin: 0 auto;
		border-bottom-width: 2px;
		border-bottom-style: solid;
	}
}

.menu_list {
	z-index: 1201;
	li {
		font-size: 16px;
		font-weight: 500;
		padding-left: 20px;
		padding-right: 20px;
		width: 100%;	
		column-gap: 10px;
		color: #000;
		&:hover {
			background-color: transparent;
		}

		svg {
			font-size: 25px;
		}
		span {
			display: none;
		}
	}
}

.login_menu_list {
	z-index: 1201;
	li {
		font-size: 16px;
		font-weight: 500;
		padding-left: 20px;
		padding-right: 20px;
		width: 350px;	
		column-gap: 10px;
		cursor:auto;
		background: #fff !important;
		svg {
			font-size: 25px;
		}
		
		&:hover {
			background-color: #fff;
		}
	}
}
.menu_link {
	text-decoration: none;
	cursor: pointer;
}

.logout {
	align-items: center;
	column-gap: 8px
}

.sign_in,
.cart_icon {
	color: #000;
	font-size: 14px;
	display: flex;
	align-items: center;
	column-gap: 5px;
	margin-left: 15px;
	text-decoration: none;
	cursor: pointer;
	white-space: nowrap;
	.border {
		border: solid 1px #000;
		border-radius: 100%;
	}
	img {
		height: 18px;
	}
	.ellipsis {
		display: flex;
		height: 26px;
		margin-right: 10px;
		letter-spacing: 2px;
	}

	@media screen and (max-width: breakpoints.$md) {
		margin-left: 8px;

		span {
			display: none;
		}
	}
}

.hamberger_btn {
	//position: absolute;
	//left: 12px;
	display: none;

	svg {
		width: 1.5em;
		height: 1.5em;
	}
}

.cart_link {
	margin-right: 15px;
}

.headsectionWithBanner {

}

@media screen and (max-width: breakpoints.$dsk) {
	.sub_header {
		display: none;
	}

	.hamberger_btn {
		display: flex;
	}

	.headsection .logosection {
		margin: 0 auto;
	}
	.sign_in_hide_mweb {
		display: none;
	}
}

@keyframes marquee {
	0% {
	  transform: translateX(100%);
	}
	100% {
	  transform: translateX(-100%);
	}
  }
  
  .marquee_container_top {
	position: relative;
	display: flex;
	top: 2px;
	width: 100%;
	//border: 1px solid green;
	height: 42px;
	overflow: hidden;
	white-space: nowrap; /* Prevents text from wrapping */
	@media screen and (max-width: breakpoints.$sm) {
		display: none;
	}
  }
  .marquee_container_bottom {
	position: relative;
	display: flex;
	width: 100%;
	//border: 1px solid green;
	height: 20px;
	overflow: hidden;
	white-space: nowrap; /* Prevents text from wrapping */
	@media screen and (max-width: breakpoints.$sm) {
		display: none;
	}
  }
  
  @keyframes marquee {
	0% {
	  transform: translateX(100%);
	}
	100% {
	  transform: translateX(-100%);
	}
  }

  .marquee_content {
	display: inline-block;
	padding-left: 100%; 
	animation: marquee linear infinite; 
  }
  .no_marquee_content {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
  }