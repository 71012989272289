.getstartViewDialogTitle {
    font-family: 'Nunito';
    font-size: 36px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.2px;
    text-align: center;
    text-underline-position: 'from-font';
    text-decoration-skip-ink: none;
    color: #FE6526;
    padding: 30px 25px 0 25px;
}

.getstartViewDialogContent {
    position: relative;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    row-gap: 50px;
    // gap:26px;
    column-gap: 20px;
    padding: 20px;
    // width: 100%;
    // height: 100%;
}
.getStartViewDivider{
    background-color: #999999;
    height: '2px';
    width: 90%;
    margin: auto;
}
.dividerX{
    position: absolute;
    top: 51%;
    left: 0;
    right:0;
    height:1px;
    border: 1px solid #999999;
    transform: translateY(-50%);
    width: 90%;
    margin: auto;
}
.dividerY{
    position: absolute;
    top: 0;
    left: 50%;
    bottom:0;
    height:90%;
    border: 1px solid #999999;
    transform: translateX(-50%);
    width: 1px;
    margin: auto;
}
.videoViewContent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0;
}

.videoContainer {
    width: 100%;
    overflow: hidden;
}

// .eachHeroTileContainer {
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//     row-gap: 8px;
// }
@media (max-width: 800px) {
    .getstartViewDialogTitle {
        font-size: 30px;
        line-height: 26px;
        padding:0;
        padding-top: 16px;
        padding-right: 24px;
        padding-left: 24px;
    }
}
@media (max-width: 600px) {
    .getstartViewDialogTitle {
        font-size: 26px;
        line-height: 26px;
        padding:0;
        padding-top: 16px;
        padding-right: 24px;
        padding-left: 24px;
    }

    .getstartViewDialogContent {
        grid-template-columns: 1fr;
        padding: 10px;
        row-gap: 10px;
    }
}