.card {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.image-wrapper {
    object-fit: contain;
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 20px;
    margin-bottom: 10px;
    &:hover {
        border: 1px solid darkgray;
    }
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.text {
    font-size: 18px;
    text-align: center;
    margin: 0px;
}

