@use '../../assets/css-vars/breakpoints' as breakpoints;

.carousel-control-prev,
.carousel-control-next {
    top: 42% !important;
    height: 5rem;
    z-index: 2;
}

.design-slideshow-panel {
    position: relative;
    // height: 100%;
    //min-height: 40rem;
    width: 100%;
    height: fit-content;

    .react-multi-carousel-list {
        height: 100%;
        overflow: visible; /* Ensure visibility of dots if hidden */

        .react-multi-carousel-track {
            height: 100%;
            margin-bottom: 40px; /* Add spacing for the dots */
        }
    }

    .slideshow {
        //   min-height: 30rem;
    }

    .sub-page-hero-e-image {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        .slide-wrapper {
            position: relative;
            width: 100%;
            padding-top: 56.25%; /* Maintain 16:9 aspect ratio */
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            aspect-ratio: 16/9;
        }
        .slide-wrapper img {
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .slide-caption {
        font-size: 130%;
        line-height: 150%;
        color: #fff;
        position: absolute;
        width: 70%;
        left: 15%;
        text-transform: uppercase;
        font-weight: bold;
        top: 15%;
        text-align: center;
        text-shadow: 3px 2px 2px rgba(0, 0, 0, 0.32);

        @media screen and (min-width: breakpoints.$md) {
            font-size: 170%;
            line-height: 200%;
        }
    }

    .load-designs {
        width: 100%;
        padding-top: 56.25%;
        text-align: center;
    }

    .carousel-dark .carousel-control-prev-icon,
    .carousel-dark .carousel-control-next-icon {
        filter: grayscale(0) !important;
    }

    .carousel-bootstrap-next-prev-icon-holder {
        display: flex;
        width: 5rem;
        height: 5rem;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    .sub-page-hero-button {
        position: absolute;
        z-index: 3;
        text-align: center;
        margin-top: -5%;
        left: 45%;
        right: 45%
    }

    .react-multiple-carousel__arrow--right {
        right: 32px;
    }

    .react-multiple-carousel__arrow--left {
        left: 32px;
    }

    .react-multi-carousel-dot-list {
        // bottom: -4px;
        position: absolute; /* Position the dots absolutely */
        bottom: -30px;       /* Adjust the distance from the bottom */
        left: 50%;          /* Center horizontally */
        transform: translateX(-50%);
        z-index: 1000;         /* Ensure it appears on top if necessary */
    }

    @media screen and (min-width: 768px) {
        .react-multi-carousel-dot-list {
            display: block; /* Ensure visibility */
        }
    }
    
    @media screen and (max-width: 767px) {
        .react-multi-carousel-dot-list {
            bottom: -20px; /* Adjust for smaller viewports */
        }
    }    


    .viewProductDetails {
        position: absolute;
        right: 0;
        width: 20%;
        bottom: 20px;
        height: 40px;
        color: #fff;
        text-align: center;
        cursor: pointer;
        z-index: 8;
        line-height: 40px;

        @media screen and (min-width: 768px) {
            width: 20%;
        }

        @media screen and (min-width: 1200px) {
            width: 20%;
        }

        span {
            display: flex;
            justify-content: center;
            white-space: 'nowrap'; 
            overflow: 'hidden'; 
            text-overflow: 'ellipsis'; 
        }

        .icon {
            width: 2em;
            height: 3em;
        }
    }

    @media screen and (max-width: 767px) {
        .viewProductDetailsWrapper {
            position: fixed;
            bottom: 0px;
            display: flex;
            width: auto;
            z-index: 1001;
            justify-content: center;
            width: 50%;
            padding: 10px 0;
            background-color: rgba(255, 255, 255, 0.8);
            box-shadow: 0px -10px 50px 0px rgba(0, 0, 0, 0.1);
            left: 0;

            .viewProductDetails {
                position: relative;
                width: auto;
                padding: 0 40px;
                white-space: nowrap;
            }

            &.noFix {
                position: relative;
                box-shadow: none;
                padding: 0;
                margin-top: 20px;
            }
        }
    }


}

// @media screen and (min-width: 480px) and (max-width: 1200px) {
//     // .design-slideshow-panel {
//     //     .sub-page-hero-e-image.img span {
//     //         min-height: 56rem;
//     //     }
//     // }
// }

.slide_wrapper img{
    border-radius: 10px;
}

@media screen and (min-width:767px) and (max-width:992px) {
    .design-slideshow-panel {

        .sub-page-hero-button {
            margin-top: -7%;
            left: 38%;
            right: 38%;
        }

        .sub-page-hero-e-image {
            position: relative;
            z-index: 1;
        }

        .sub-page-hero-e-image img {
            width: 100%;
        }
    }
}

@media screen and (min-width:768px) {
    .react-multi-carousel-dot-list {
        display: none;
    }
}

@media screen and (max-width:767px) {
    .design-slideshow-panel .react-multi-carousel-track {
        margin-bottom: 30px;
    }
}

@media screen and (max-width: breakpoints.$sm) {
    .slide_wrapper {
        height: 300px !important;
    }
}