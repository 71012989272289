@use '../../../assets/css-vars/breakpoints' as breakpoints;

.commonCarouselWrapper {
    display: flex;
    align-items: center;
    width: calc(100% - 60px);
    &.full_width {
        width: 100%;
    }
    position: relative;

    .noscrollContainer {
        // width: calc(100% - 60px);
        overflow-y: hidden;
        -ms-overflow-style: none;
        /* Internet Explorer 10+ */
        scrollbar-width: none;
        /* Firefox */
        display: flex;
        // margin: 0 auto;
    }

    .noscrollContainer::-webkit-scrollbar {
        display: none;
        /* Safari and Chrome */
    }

    .arrowBtns {
        position: absolute;
        height: 100%;
        top: 0;
        cursor: pointer;
        width: 100px;
        display: flex;
        align-items: center;
        z-index: 2;

        svg {
            font-size: 25px;
        }

        &.prev {
            left: 0px;
            background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
            justify-content: flex-start;
        }

        &.next {
            right: 0px;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
            justify-content: flex-end;
        }
        @media screen and (max-width: breakpoints.$sm) {
            width: auto;
            &.prev {
                left: -25px;
                background: none;                
            }
            &.next {
                right: -25px;
                background: none;
            }
        }
    }

    @media screen and (max-width: breakpoints.$sm) {
        &:not(.scrollable) {
            .noscrollContainer {
                overflow-y: hidden;
                flex-wrap: wrap;
                margin-left: -5px;
                height: 48px;

                p {
                    padding: 0;
                }
            }

            .arrowBtns {
                display: none;
            }
        }
    }
}

.show_more {
    display: none;
}

@media screen and (max-width: breakpoints.$sm) {
    .show_more {
        display: flex;
        position: relative;
        justify-content: right;
        margin-bottom: 10px;
        &:before {
            position: absolute;
            content: "";
            left: 0px;
            background-color: #C4C4C4;
            height: 1px;
            width: 100%;
            top: 50%;
            z-index: 1;
        }

        a {
            text-decoration: underline;
            text-decoration-color: #25282B;
            background-color: #fff;
            color: #25282B;
            font-size: 14px;
            padding-left: 10px;
            z-index: 2;
        }
    }
}