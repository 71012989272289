
.primary__btn {
    font-size: 16px;
    border-radius: 20px;
    text-transform: none;
    display: flex;
    width: 100%;
    border: solid 1px transparent;
    &:hover {
        background-color: #fff !important;        
    }
}