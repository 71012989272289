.next-button {
    color: #fff !important;
    text-transform: none !important;
    display: flex;
    min-width: 200px !important;
}
.popup{
    text-align: center;
}
.cartItemsContainer{
    div{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        column-gap: 30px;
        border-bottom: 1px solid;
        padding-bottom: 5px;
        p{
            font-size: 16px;
        }
       
    }
}
.yes{
    background-color: #FE6526;
    color: #fff;
    border: none;
    border-radius: 25px;
    font-size: 19px;
    cursor: pointer;
}
.no{
    background-color: #fff;
    color: #FE6526;
    border: 1px solid #FE6526;
    border-radius: 25px;
    font-size: 19px;
    cursor: pointer;
}