@use '../../assets/css-vars/breakpoints' as breakpoints;

.slideshow-container {
    margin-bottom: 30px;
}

.heading {
    margin-top: 30px;
    text-align: center;
}

.login_left-section {
    max-width: 400px;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
}

.logo {
    margin: 30px 0 30px 0;
    max-width: 200px;
}

.login-left-container {
    margin-bottom: 50px;
}

.facebook_login {
    border: solid 1px #ddd;
    border-radius: 20px;
    padding: 10px 10px;
    margin-top: 10px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer;
    border-color: rgba(0, 0, 0, 0.2);

    span {
        font-size: 15px;
        font-weight: 600;
        padding-left: 10px;
        color: #6a6a6a;
    }

    svg {
        color: #3d5a98;
    }

    margin-bottom: 20px;
}

.google-button-wrapper {
    height: 50px;

    .google-button-section {
        position: relative;

        .custom-google-button {
            position: absolute;
            pointer-events: none;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            color: #6a6a6a;
            background-color: #fff;
            border: solid 1px rgba(0, 0, 0, 0.2);
            padding: 10px 0;
            font-weight: 600;
            border-radius: 20px;

            img {
                width: 18px;
            }

            span {
                padding-left: 10px;
            }
        }

        .real-google-button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -0%);
        }
    }
}


.apple_login {
    border: solid 1px #ddd;
    border-radius: 20px;
    padding: 10px 10px;
    margin-top: 10px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer;
    border-color: rgba(0, 0, 0, 0.2);

    span {
        font-size: 15px;
        font-weight: 600;
        padding-left: 10px;
        color: #6a6a6a;
    }

    svg {
        color: #3d5a98;
    }

    margin-bottom: 15px;
}

@media screen and (min-width: breakpoints.$md) {
    .slideshow-container {
        height: 100%;
        width: 50%;
        position: absolute;
        right: 0;
        top: 0;

        ul li span {
            height: 100vh !important;
        }
    }

    .login-left-container {
        position: absolute;
        width: 50%;
        left: 0;
        padding: 0 50px;
    }

    .heading {
        margin-top: 20px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: breakpoints.$dsk) {
    .slideshow-container {
        display: none;
    }

    .login-left-container {
        width: 100%;
    }
}

.style_profile_signup {
    background: #fff;
    display: flex;
    flex-direction: column;
    padding: 10px;
    h3 {
        text-align: center;
        font-family: Lora;
        font-size: 20px;
        font-style: italic;
    }
    h4 {
        font-weight: normal;
        font-size: 16px;
        text-align: center;
        a {
            color: #000;
            text-decoration: underline;
        }
    }
}