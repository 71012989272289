.container{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.formContainer{
  height: 100%;
  // padding: 2% 3%;
  align-items: center;
  width: 46%;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  // margin-top: 70px;
}
.formContainer1{
  height: 100%;
  // padding: 2% 3%;
  width: 46%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
  // margin-top: 70px;
}
.Mwebcontainer{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.MwebHeaderText{
    margin: 10px;
    font-size: 24px !important;
    font-weight: 700 !important;
}
.category .imageWrapper:hover + p {
    color: #00a99d;  
    // font-size: 17px;
    font-weight: 500;
}
.ftAndIn{
  display: flex;
  color: gray;
  margin-top: 21px;
}
.mainContainer{
  height: 665px;
  width: 93%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
  border-radius: 25px;
  overflow-y: auto;
}
.CircularImg{
    height: 100%;
    width: 100%;
    // margin-bottom: 20px;
    border-radius: 50%;
    object-fit: cover;
    border: 4px solid transparent;
    transition: border-color 0.3s ease;
}
.category img {
    border-radius: 50%;
    object-fit: cover;
    border: 4px solid transparent;
    transition: border-color 0.3s ease;
  }
.category p {
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 16px;
    color: #4a4a4a;
    margin-top: 0;
    margin-bottom: 9px;
  }
.category img:hover {
border-color: #00a99d; /* Highlight border on hover */
}

.category .imageWrapper:hover + p {
color: #00a99d;  
// font-size: 17px;
font-weight: 500;
}
.category .overlayText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 14px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    pointer-events: none; /* Make sure this doesn't affect hover on the image */
}
.formGroupColorFloor{
  width: 100%;
}
.form {
    width: 525px;
    height: 310px;
    padding:3%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

  
    &Group {
      display: flex;
      flex-direction: row;
      text-align: left;
      // margin-bottom: 22px;
      height: 38px;
      width: 100%;
  
      label {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        width: 40%;
        font-size: 24px;
      }
  
      input[type="text"] {
        padding: 8px;
        font-size: 1rem;
        border: 1px solid #000000;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        font-size: 24px;
      }
    }
  
    &GroupDimensions {
      margin-bottom: 22px;
      display: flex;
      height: 16%;
      width: 100%;
  
        label {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;
            width: 40%;
            font-size: 24px;
        }
        .inputPair {
        display: flex;
        justify-content: space-between;
        gap: 1%;
        margin-left: 0%;
        width: 100%;
        /* Your CSS module file */
         input[type="number"]::-webkit-outer-spin-button,
         input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

         input[type="number"] {
            -moz-appearance: textfield;
        }
        
  
        input {
          flex: 1;
          padding: 8px;
          font-size: 1rem;
          border: 1px solid #000000;
          border-radius: 5px;
          height: 40px;
          width: 154px;
          font-size: 24px;
          
        }
      }
    }
  
    &GroupColorFloor {
      display: flex;
      justify-content: space-between;
      
      label {
        height: 32px;
        width: 132px;
        font-size: 24px;
      }
  
      .colorContainer,
      .floorContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
  
        .colorPicker,
        .floorPicker {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 2px solid #ccc;
          background-color: #f5f5f5;
        }
      }
    }
  }
.mainButton{
    background-color: #FE6526;
    width: 441px;
    height: 47px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    // margin-top: 33px;
    &:hover {
        background-color: #e55b1d;  // Darker shade on hover
    }
}
.CircleContainer{
    width: 615px;
    height: 615px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.overlayText{
  // display: flex;
  // justify-content: center;
  margin: 0;
  font-size: 15px;
  font-family: 'Nunito';
}

.roomPlanTabPanel{
  width: 25% !important;
}

.optionsContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  // height: 378px;
  height: 423px;

  &Option{
    border: solid;
    border-radius: 13px;
    border-color: #095F6A;
    color: #095F6A;
    width: 363px;
    height: 87px;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    
      &Text{
        font-size: 13px;
        font-weight: 500;
      }
  }
}
.header1{
  width: 100%;
  height: 91px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // width: 100%;
  // height: 91px;
  // margin-bottom: 15px;
}
.CloseIcon{
  display: flex;
  justify-content: end;
  width: 100%;
  position: relative;
  bottom: 12px;
  cursor: pointer;
  padding-top: 35px;

}
.BackIcon{
  height: 92%;
  cursor: pointer;
  width: 1%;

}
.qr_code_img{
  height: 100%;
  width: 100%;
}
.buttonContainer{
  justify-content: center;
  display: flex;
  align-items: end;
  width: 100%;
}
.header{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10%;
  padding-bottom: 9%;
  padding-top: 9%;
  // margin-bottom: 60px;
// width: 482px;
// height: 91px;
// margin-bottom: 60px;

    &Text1{
        // margin-top: 35px;
        // line-height: 33;
        letter-spacing: 0.1px;
        font-size: 27px;
        color: #25282B;
        font-weight: 600;
        font-family: Nunito, Lora;
        justify-content: center;
        display: flex;
        align-items: center;
        line-height: 1;
    }
    &Text2{
        margin-top: 12px;
        // line-height: 33;
        letter-spacing: 0.1px;
        font-size: 27px;
        color: #25282B;
        font-weight: 600;
        font-family: Nunito, Lora;
        justify-content: center;
        display: flex;
        align-items: center;
    }
    &Text3{
      margin-top: 19px;
      letter-spacing: 0.1px;
      font-size: 15px;
      color: #25282B;
      font-weight: 600;
      font-family: Nunito, Lora;
      justify-content: center;
      display: flex;
      align-items: center;
    }
    &Text4{
      margin-top: 2px;
      font-size: 15px;
      color: #25282B;
      font-weight: 600;
      font-family: Nunito, Lora;
      justify-content: center;
      display: flex;
      align-items: center;
    }
    &Text5{
      // margin-top: 35px;
      letter-spacing: 0.1px;
      font-size: 15px;
      color: #25282B;
      font-weight: 600;
      font-family: Nunito, Lora;
      justify-content: center;
      display: flex;
      align-items: center;
    }
    &Text6{
      margin-top: 6px;
      // line-height: 33;
      letter-spacing: 0.1px;
      font-size: 19px;
      color: #25282B;
      font-weight: 600;
      font-family: Nunito, Lora;
      justify-content: center;
      display: flex;
      align-items: center;
  }
}
@media (min-width: 374px) and (max-width: 1024px) {
  .container{
    height: 92%;
    width: 92%;
  }
  .containerCreateRoomPlan{
    height: 92%;
    width: 100%;
  }
  .roomPlanTabPanel{
    width: 80% !important;
  }
  .formContainer{
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
  }
  .backAndCloseIconMWeb{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 6px 15px 0;
    align-items: center;
  }
  .CloseIconMWeb{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 6px 15px 0;
    align-items: center;
  }
  .logo_img{
    height: 100%;
    width: 55%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 21%;
  }
  .qr_code_img{
    width: 60%;
    margin: 12px;
  }
  .header{
    
    &Text3{
          margin-top: 17px;
    }
    &Text4{
      margin-top: 2px;
      font-size: 15px;
      color: #25282B;
      font-weight: 600;
      font-family: Nunito, Lora;
      justify-content: center;
      display: flex;
      align-items: center;
      display: flex;
      justify-content: center;
  }
  &note{
    margin-top: 2px;
    font-size: 12px;
    color: #25282B;
    font-weight: 600;
    font-family: Nunito, Lora;
    justify-content: center;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: center;
    font-weight: bolder;
  }
  }
  .CircleContainer{
    margin: 0;
    height: 32%;
    width: 52%;
}
  .optionsContainer{
  height: 70%;
    &Option{
      width: 90%;
      height: 25%;
    }
    &Text{
      width: 90%;
      border: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 5%;
    }
  }
  .optionsContainer{
  
    &Option{
      
        &Text{
          font-size: 16px;
          font-weight: 500; 
          text-align: center;
        }
    }
  }
  .header{
    margin-bottom: 10px;
  }
  .header1{
    padding: 8px 10px 8px;
    height: 20%;
    // margin-top: 10%;
    // margin-bottom: 5%;
    // margin-bottom: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mainContainer{
    overflow-y: auto;
    overflow-x: hidden;
    height: 637px;
    width: 482px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:flex-start !important;
    // align-items: center;
    background-color: white;
    border-radius: 25px;
}
  .CircularImg{
    height: 100%;
    width: 100%;
    // margin-bottom: 20px;
    border-radius: 50%;
    object-fit: cover;
    border: 4px solid transparent;
    transition: border-color 0.3s ease;
}
    .ftAndIn{
      display: flex;
      color: gray;
      margin-top: 11px;
    }
    .overlayText{
      // display: flex;
      // justify-content: center;
      margin: 0;
      font-size: 10px;
      font-family: 'Nunito';
    }
    .noteText{
        display: flex;
        height: 60px;
        font-size: 15px;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 14px;
        color: #AAAAAA;
    }
    .mainButton{
        background-color: #FE6526;
        margin:0;
        width: 64%;
        height: 40px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        &:hover {
            background-color: #e55b1d;  // Darker shade on hover
        }
    }
    .mainContainer{
        height: 100%;
        width: 100%;
        background-color: white;
    }
    .header{
        width: 482px;
        display: flex;
        flex-direction: column;
    }
    .header{
  
      &Text1{
          font-size: 22px;
      }
    }
    .imgContainer1{
      width: 55%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .imgContainer{
      width: 75%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .form {
        width: 92%;
        height: 43%;
      
        &Group {
          display: flex;
          flex-direction: row;
          text-align: left;
          // margin-bottom: 22px;
          height: 40px;
          width: 100%;
          justify-content: space-between;
      
          label {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 23px;
            width: 20%;
            font-size: 24px;
          }
      
          input[type="text"] {
            padding: 8px;
            font-size: 1rem;
            border: 1px solid #000000;
            border-radius: 5px;
            width: 100%;
            box-sizing: border-box;
            height: 32px;
            width: 70%;
            font-size: 12px;
          }
        }
      
        &GroupDimensions {
          margin: 11px 0px 11px;
          // margin-bottom: 22px;
          display: flex;
          height: 14%;
          width: 100%;
          justify-content: space-between;
          align-items: center;
      
            label {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 23px;
                width: 20%;
                font-size: 24px;
            }
            .inputPair {
            display: flex;
            justify-content: space-between;
            gap: 10px;
            width: 70%;
            /* Your CSS module file */
             input[type="number"]::-webkit-outer-spin-button,
             input[type="number"]::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

             input[type="number"] {
                -moz-appearance: textfield;
            }
      
            input {
              flex: 1;
              padding: 8px;
              border: 1px solid #000000;
              border-radius: 5px;
              height: 32px;
              width: 50%;
              font-size: 12px;
            }
          }
        }
      
        &GroupColorFloor {
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            align-items: center;
          
          label {
            height: 32px;
            width: 132px;
            font-size: 24px;
          }
      
          .colorContainer,
          .floorContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 20px;
      
            .colorPicker,
            .floorPicker {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              border: 2px solid #ccc;
              background-color: #f5f5f5;
            }
          }
        }
      }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .form {
    width: 65%;
    height: 35%;
  }
  .mainButton{
    width: 65%;
  }

  .optionsContainer{
    width: 78%;
    margin: 0% 11% 0;
    &Option{
      
        &Text{
          font-size: 21px;
        }
  }
}
  .header{
    &note{
      font-size: 23px;
    }
  }
  .formContainer{
    height: 100%;
    width: 100%;
  }
  .imgContainer{
    width: 50%;
    margin-top: 4%;
  }
  .qr_code_img{
    width: 45%;
  }
  .CircleContainer{
    margin: 0;
    height: 27%;
    width: 41%;
}
  .header{
  
      &Text1{
          font-size: 37px !important;
      }
      &Text3{
        font-size: 20px !important;
      }
      &Text4{
        font-size: 20px !important;
      }

    }
    .header1{
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 0;
      height: 11%;
    }
    .CircularImg{
      height: 100%;
      width: 100%;
      // margin-bottom: 20px;
      border-radius: 50%;
      object-fit: cover;
      border: 4px solid transparent;
      transition: border-color 0.3s ease;
  }

}
