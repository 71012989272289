@use '../../assets/css-vars/breakpoints' as breakpoints;

.horiz-menu>ul>li {
    display: inline-block;
}

.MuiModal-backdrop {
    background-color: transparent !important;
}

.menu-cards {
    flex: 0 0 auto
}

.noscroll-container {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.noscroll-container::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.products-panel {
    text-align: center;

    .min-menu-width {
        width: 60%;
    }

    .product-card {
        margin: 0 auto;
        background-color: transparent;
        box-shadow: none;
        border: unset;
        width: calc(100% - 40px);
        margin-bottom: 10px;

        .product-card-header {
            border-style: solid;
            border-width: 4px;
        }

        img {
            padding: 0;
            border: solid 2px transparent;
            box-sizing: border-box;

            &:hover {
                border-color: #fff;
            }
        }
    }

    .product-card-header {
        padding: 0px;
    }

    .product-cards-title {
        width: calc(100% - 8px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        margin: 0 auto;
    }

    .product-cards-title {
        text-align: left;
    }

    .strike-through {
        text-decoration: line-through;
        margin-right: 10px;
    }

    .pointer {
        cursor: pointer;
    }

    .see-more-like-this {
        text-decoration: underline;
        font-size: 14px;
        line-height: 12px;
        letter-spacing: 0.1px;
        text-align: right;
        display: block;
        padding: 0 10px 0 0;
        margin-bottom: 10px;
    }

    @media screen and (max-width: 767px) {
        .product-card {
            position: relative;
            margin: 0;
            border-bottom: solid 1px rgba(160, 164, 171, 0.25);
            padding-bottom: 20px;
            margin-bottom: 20px;
            width: 100%;

            &:last-child {
                border-bottom-color: transparent;
                padding: 0;
                margin: 0;
            }

            .product-link {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                height: 14rem;
                margin-bottom: 10px;
                column-gap: 20px;

                img {
                    max-height: 100%;
                    max-width: 100%;
                    object-fit: contain;
                }
            }

            .product-card-header {
                width: 50%;
                height: 100%;

                >div,
                >div>span {
                    height: 100%;
                }

                border: none;
            }

            .product-cards-title {
                width: 50%;
                white-space: normal;
            }

            .see-more-like-this {
                text-align: left;
            }
        }
    }
}

.card {
    display: flex;
    flex-direction: column;
}

.image-wrapper {
    height: 0;
    padding-bottom: calc(80% - 50px);
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
}

.image-wrapper img {
    width: 100%;
    height: 100%;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
}

// .image-wrapper span {
//     background: rgba(0, 0, 0, 0.5);
//     padding: 8px 10px;
//     display: flex;
//     align-items: center;
//     column-gap: 5px;
//     width: 100%;
//     border-radius: 0px 0px 10px 10px;
//     text-align: center;
//     justify-content: center;
//     color: #fff;
//     font-weight: bold;
//     font-size: 16px;
// }

.image-wrapper span img {
    position: relative;
    width: 20px;
}

.top-icon-container {
    top: 10px;
    width: 95%;
    position: relative;
    display: flex; /* Make it a flex container */
    justify-content: flex-end; /* Align items to the right */
    gap: 10px; /* Space between items */
  }
  
.top-icon-dsk {
    position: absolute;
    width: 25px; /* Icon width */
    height: 25px; /* Icon height */
    background-color: white; /* Red background */
    border-radius: 50%; /* Circle shape */
    display: flex; /* To center the image inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    z-index: 1; /* Place it on top */
    cursor: pointer;
    bottom: 10px;
    left: 10px;
    z-index: 1000;
}

.top-icon-mobile {
    display: none;
}

.text {
    flex: 1 0 auto;
    padding-bottom: 10px;
    padding-top: 10px;
    justify-items: center;
}

.view-in-room-mobile {
    display: none;
}

.sale {
    position: relative;
    display: inline-block;
    background: orange;
    color: white;
    height: 20px;
    width: 20px;
    text-align: center;
    vertical-align: middle;
    line-height: 20px;
    margin: 5px 10px;
    transform: rotate(-20deg);
    animation: beat 1s ease infinite alternate;
    font-size: 9px;

    &:before,
    &:after {
        content: "";
        position: absolute;
        background: inherit;
        height: inherit;
        width: inherit;
        top: 0;
        left: 0;
        z-index: -1;
        transform: rotate(30deg);
    }

    &:after {
        transform: rotate(60deg);
    }
}

@keyframes beat {
    from {
        transform: rotate(-20deg) scale(1);
    }

    to {
        transform: rotate(-20deg) scale(1.1);
    }
}

@media screen and (max-width: breakpoints.$md) {
    .text * {
        text-align: center;
        justify-content: center;
    }

    .top-icon-mobile {
        position: absolute;
        width: 20px; /* Icon width */
        height: 20px; /* Icon height */
        background-color: white; /* Red background */
        border-radius: 50%; /* Circle shape */
        display: flex; /* To center the image inside */
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        z-index: 100; /* Place it on top */
        cursor: pointer;
        bottom: 10px;
        left: 10px;
    }
    
    .top-icon-dsk {
        display: none;
    }

    .view-in-room-dsk {
        display: none;
    }

    .view-in-room-mobile {
        display: flex;
        justify-content: center;

        button,
        button:hover {
            white-space: nowrap;
            background: none;
            min-width: fit-content;
            border: none;
            position: relative;
            display: flex;
            width: 150px;
            height: 40px;
        }
    }
}

.instruction_tip {
    padding: 20px;
    display: none;

    h3 {
        display: block;
        text-align: center;
        padding: 15px;
        text-transform: none;
        font-family: Lora;
        font-weight: 400;
        font-size: 17px;
    }

    .upload_header {
        display: flex;
        align-items: center;

        img {
            max-width: 100%;
        }

        p {
            padding: 30px;
            text-align: center;
        }
    }
}

.step_container_scan {
    width: 100%;
    height: 100%;

    p {
        display: flex;
        padding: 5px 20px;
        font-size: 12px;
        column-gap: 15px;
        align-items: center;
        color: #000;
        font-weight: bold;
        height: 50px;
        line-height: 18px;
        margin: 0;
        border-radius: 15px 15px 0 0;

        span {
            flex-shrink: 0;
            width: 30px;
            height: 30px;
            background: rgba(150, 207, 205, 0.20);
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000;
            border: solid 1px #000;
        }
    }

    .step_container_desp {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        padding: 0;
        flex-direction: column;

        p {
            margin-top: 20px;
            padding: 10px 25px;
        }

        img {
            max-width: 100%;
            max-height: 200px;
            object-fit: cover;
            border: solid 1px #000;
            border-radius: 10px;
        }
    }
}

.app_download {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    img {
        max-width: 180px;
    }
}

.lets_design {
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    width: 90%;
    text-align: center;
    margin-top: 20px;
    font-weight: 700;
}

@media screen and (max-width: breakpoints.$md) {
    .instruction_container {
        padding: 0;
        box-shadow: none;
    }

    .instruction_footer {
        box-shadow: none;
        padding: 20px 0;
    }

    .instruction_tip {
        padding: 0
    }
}

@media screen and (max-width: breakpoints.$dsk) {
    .instruction_tip {
        display: block;
    }

    .room_selector {
        display: none !important;
    }
}

@media screen and (max-width: breakpoints.$sm) {
    .products-panel {
        text-align: center;

        .min-menu-width {
            width: 60%;
        }

        .product-card {
            text-align: center;
            border-bottom: none;

            .product-link {
                flex-direction: column-reverse;
                height: auto;
                margin-bottom: 0px;
            }

            .see-more-like-this {
                text-align: center;
                margin-top: 10px;
            }

            .product-cards-title {
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: breakpoints.$dsk) {

    .view-in-room-dsk,
    .view-in-room-mobile {

        button,
        button:hover {
            padding: 2px 15px;
            font-size: 11px;

            img {
                width: 20px;
            }
        }
    }
}

/* Add a bestSeller icon at the top-left corner of the design item */
.bestSeller-icon {
	position: absolute;
	display: block !important;
	visibility: visible !important;
    width: 3.0rem; /* Adjust as needed */
    height: 3.0rem;
    top: 10px; /* Adjust as needed */
    left: 5px;
    z-index: 1000;
    pointer-events: none;
}

  .button-3d {
    font-size: 8px;
    position: relative;
    // bottom: 5px;
    // left: 10px;
    display: flex;
    // flex-direction: column;
    align-items: center;
    svg {
        border-radius: 100%;
        background: #D9D9D9;
        padding: 5px;
        font-size: 30px;
        margin: 5px;
    }
}

@media screen and (max-width: breakpoints.$md) {
    .button-3d {
        bottom: 10px;
    }
}