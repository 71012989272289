@use './../../assets/css-vars/breakpoints' as breakpoints;

.avatar-dialog-section .MuiPaper-root {
    max-width: 85% !important;
}

#my-home>.MuiPaper-root,
#my-home>.MuiPaper-root>.MuiPaper-root {
    max-width: 100% !important;
}

#my-home {
    #style-profile-main {
        max-width: 85%;
        margin: 0 auto;
    }


    h1 {
        font-size: 30px;
        text-align: center;
    }
}

.video-for-clap {
    width: "100%";
    max-width: 222px;
    height: auto;
    display: block;
}

.share-background {
    background: rgba(217, 217, 217, 0.35);
}

.adjustLikesGotoHome {
    display: flex !important;
    margin-top: 15px !important;
    text-transform: none !important;
    margin: 10px auto !important;
}

.adjustLikesGoToSelf {
    display: flex !important;
    color: primary;
    background-color: #FFFFFF;
    border: 1px solid primary;
    text-transform: none;
    margin: 10px auto !important;
}

.adjustLikes {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.share-dialog-title {
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 30px !important;
}

.avatar-personaDescription {
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 27.5px !important;
}

.avatar-styleProfileDescription {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 17.5px !important;
}

.room-icon {
    font-size: 3.3rem !important;
}

.room-icon-text {
    letter-spacing: -0.5px !important;
    font-size: 12px !important;
    line-height: 12px !important;
}

.bathroom {
    top: 16px !important;
    left: 40px !important;
}

.kitchen {
    left: 214px !important;
    top: 24px !important;

}

.bedroom {
    top: 123px;
    left: 40px;
}

.homeOffice {
    left: 209px !important;
    top: 122px;

    .room-icon-text {
        margin-left: -25px !important;
    }
}

.livingRoom {
    left: 68px !important;
    top: 218px !important;
}

.dining {
    top: 218px !important;
    left: 209px !important;
}

@media screen and (min-width: 1150px) {
    #my-home {
        #style-profile-main {
            max-width: 65%;
        }
    }
}

.tear_shape,
.tear_shape_small {
    width: 224px;
    aspect-ratio: 1;
    background: rgba(0, 0, 0, 0.4);
    rotate: 55deg;
    border-radius: 0 50% 50% 50%;
    transform: rotate(-145deg);
    overflow: hidden;
    flex-shrink: 0;
    position: relative;

    video {
        rotate: 90deg;
        right: 20px;
        height: 100%;
        width: auto;
        position: relative;
        max-width: initial;
    }

    .video_hover {
        opacity: 0;
        position: absolute;
        top: 10px;
        bottom: 0;
        transform: rotate(90deg);
        width: 60px;
        height: 60px;
        background: rgba(0, 0, 0, 0.2);
        left: 10px;
        right: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        color: #fff;
        transition: opacity 0.2s ease-in-out;
    }

    &:hover {
        .video_hover {
            opacity: 1;
        }
    }
}

.tear_shape_small {
    width: 124px;

    video {
        right: 11px;
    }
}

.header_title {
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1px;

    .header_name {
        font-size: 30px;
        font-weight: 600;
    }
}

.header_info {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1px;
    margin-top: 30px;
}

.avatar_icon {
    cursor: pointer;
    display: block;
    height: 160px;
    margin: 0 auto;
}

.choose_avatar {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-top: 10px;
    border-radius: 10px;
    cursor: pointer;
    color: #18A0FB;
    font-size: 14px;

    img {
        width: 25px;
        margin-right: 10px;
        rotate: 270deg;
    }
}

.photoContainer {
    a {
        text-decoration: none;
    }

    @media screen and (max-width: 640px) {
        display: flex;
        column-gap: 30px;
        flex-direction: column;
        align-items: center;

        .choose_avatar img {
            rotate: none;
        }
    }
}

.header_section {
    /*display: flex;*/
    column-gap: 50px;

    @media screen and (max-width: 640px) {
        flex-direction: column;
        row-gap: 30px;
    }
}

.avt_btn {
    margin-top: 10px;
    background-color: #18A0FB;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    &:hover {
        text-decoration: none;
        background-color: #18A0FB;
    }
}

.divider {
    border-top: solid 1px #18A0FB
}

.title {
    font-size: 25px;
    font-weight: 700;
}

.product_img {
    margin-top: 30px;

    img {
        width: 100%;
        margin: 0;
        height: 400px;
    }
}

.loading {
    height: calc(100vh - 75px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.desktopImageTileHolder {
    display: grid;
    grid-template-columns: calc(50% - 40px) calc(50% - 40px);
    grid-gap: 20px 80px;
    width: 100%;


    .imageTile {
        position: relative;

        .cta_overlay {
            background: rgba(0, 0, 0, 0.2);
            opacity: 0;
            transition: opacity 0.2s ease-out;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: calc(100% - 10px);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: solid 3px transparent;
            box-sizing: border-box;
            border-radius: 15px;

            @media screen and (max-width: 1024px) {
                align-items: end;
                opacity: 1;
                padding-bottom: 10px;
                background: none;
            }
        }

        &:hover {
            .cta_overlay {
                opacity: 1;
            }
        }

        img:not(.icon) {
            cursor: pointer;
            max-width: 100%;
            border: solid 3px transparent;
            box-sizing: border-box;
            transition: border ease-out 500ms;
            border-radius: 15px;

            &:hover,
            &.active {
                border-color: #18A0FB;
            }

            @media screen and (max-width: 640px) {
                border-radius: 10px;
                border-width: 2px;
            }
        }

        img.icon {
            width: 30px;
            vertical-align: middle;
            margin-right: 10px;

            @media screen and (max-width: 640px) {
                width: 20px;
            }
        }

        @media screen and (max-width: 640px) {
            h6 {
                font-size: 14px;
            }
        }
    }


    .imageTileRandom {
        position: relative;


        .cta_overlay {
            background: rgba(0, 0, 0, 0.2);
            opacity: 0;
            transition: opacity 0.2s ease-out;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            // height: calc(100% - 10px);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: solid 3px transparent;
            box-sizing: border-box;
            border-radius: 15px;

            @media screen and (max-width: 1024px) {
                align-items: end;
                opacity: 1;
                padding-bottom: 10px;
                background: none;
            }
        }

        &:hover {
            .cta_overlay {
                opacity: 1;
            }
        }

        img:not(.icon) {
            cursor: pointer;
            max-width: 100%;
            border: solid 3px transparent;
            box-sizing: border-box;
            transition: border ease-out 500ms;
            border-radius: 15px;

            &:hover,
            &.active {
                border-color: #18A0FB;
            }

            @media screen and (max-width: 640px) {
                border-radius: 10px;
                border-width: 2px;
            }
        }

        img.icon {
            width: 30px;
            vertical-align: middle;
            margin-right: 10px;

            @media screen and (max-width: 640px) {
                width: 20px;
            }
        }

        @media screen and (max-width: 640px) {
            h6 {
                font-size: 14px;
            }
        }
    }
}

.design_room_btn {
    display: flex;
    margin-bottom: 45px;
    text-transform: none;
    margin: 40px auto 0px auto;
}

.lets_design {
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    width: 90%;
    text-align: center;
}

.card_holder {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    padding: 0 20px;
}

.share_profile {
    display: flex;
    column-gap: 15px;
    align-items: center;

    h1 {
        font-size: 18px !important;
        line-height: 28px !important;
        font-weight: normal !important;
    }
}

.scan_images {
    width: auto;
    margin-left: auto;
    margin-right: auto;
}

.card_box {
    width: 100%;
    margin: 10px 0;
    border: 1px solid rgba(227, 227, 227, 0.3);
    text-align: center;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.28);
    transition: box-shadow 0.2s ease-in;
    box-sizing: content-box;
    border-radius: 10px;
    background: linear-gradient(0deg, rgba(227, 227, 227, 0.3) 0%, rgba(255, 255, 255, 1) 20%);
    margin-top: 20px;
    border-color: #6FDADF;
    ;

    .card_box_header {
        background: #6FDADF;
        border-bottom: 1px solid rgba(227, 227, 227);
        font-weight: 500;
        color: #fff;
        margin-bottom: 0px;
    }

    &:hover {
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.28);
    }

    &.active {
        border-color: #FE6526;

        .card_box_header {
            background: #FE6526;
            color: #fff;
        }
    }


}

.header_right {
    display: flex;
    flex-direction: column;

    .info {
        padding: 0;
        width: 90%;
        margin: 0px auto;
        font-size: 14px;
        text-align: center;
        margin-top: 20px;
    }
}

.share_section {
    display: flex;
    align-items: center;

    img {
        max-width: 50%;
    }

    h3 {
        font-size: 14px;
        text-align: center;
    }
}

.share_style_profile {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}


.product_img {
    position: relative;

    .select_design_section {
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        // text-align: center;
        // background: rgba(0, 0, 0, 0.6);
        // width: 100%;
        // height: 100%;
        display: none;
        // flex-direction: column;
        // justify-content: center;
        // h2 {
        //     color: #fff;
        //     font-size: 40px;
        //     font-family: 'Lora';
        //     font-style: italic;
        //     font-weight: normal;
        // }
    }

    img {
        width: 100%;
        height: auto;
    }
}


@media screen and (max-width: 640px) {
    .show_in_mobile {
        display: flex;
    }

    .hide_in_mobile {
        display: none;
    }

    .header_title {
        font-size: 24px;

        .header_name {
            font-size: 24px;
        }
    }

    .header_info {
        font-size: 16px;
        margin-top: 15px;
        letter-spacing: normal;
        line-height: 22px;

        &:not(.expanded) {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            /* Number of lines */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .title {
        font-size: 24px;
    }

    .desktopImageTileHolder {
        grid-template-columns: calc(50% - 5px) calc(50% - 5px);
        grid-gap: 20px 10px;
    }



    .share_style_profile {
        justify-content: center;
    }

    .card_holder {
        flex-direction: column;
        width: 100%;

        .card_box {
            width: auto;
        }
    }

    .share_profile {
        flex-direction: column;
        row-gap: 20px;
    }

    .share_section {
        flex-direction: column;
        text-align: center;

        img {
            max-width: 100%;
        }
    }

    .share_style_profile {
        position: fixed;
        bottom: -1px;
        display: flex;
        justify-content: center;
        left: 0;
        right: 0;
        margin: 0 auto;
        background: #fff;
        padding: 15px 0;
        padding-top: 35px;
    }
}

@media screen and (min-width: 641px) {
    .show_in_mobile {
        display: none;
    }

    .hide_in_mobile {
        display: flex;
    }
}

@media screen and (max-width: breakpoints.$dsk) {
    .desktopImageTileHolder {
        grid-template-columns: auto;
    }
}

.instruction_tip {
    padding: 20px;
    display: none;

    h3 {
        display: flex;
        justify-content: center;
        padding: 15px;
        color: #fff;
        text-transform: none;
        margin-top: 20px;
    }

    .upload_header {
        display: flex;
        align-items: center;

        img {
            max-width: 100%;
        }

        p {
            padding: 30px;
            text-align: center;
        }
    }
}

.step_container_scan {
    width: 100%;
    height: 100%;

    p {
        display: flex;
        padding: 5px 20px;
        font-size: 12px;
        column-gap: 15px;
        align-items: center;
        color: #000;
        font-weight: bold;
        height: 50px;
        line-height: 18px;
        margin: 0;
        border-radius: 15px 15px 0 0;

        span {
            flex-shrink: 0;
            width: 30px;
            height: 30px;
            background: rgba(150, 207, 205, 0.20);
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000;
            border: solid 1px #000;
        }
    }

    .step_container_desp {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        padding: 0;
        flex-direction: column;

        p {
            margin-top: 20px;
            padding: 10px 25px;
        }

        img {
            max-width: 100%;
            max-height: 200px;
            object-fit: cover;
            border: solid 1px #000;
            border-radius: 10px;
        }
    }
}

.app_download {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    img {
        max-width: 180px;
    }
}

@media screen and (max-width: breakpoints.$md) {
    .instruction_container {
        padding: 0;
        box-shadow: none;
    }

    .instruction_footer {
        box-shadow: none;
        padding: 20px 0;
    }

    .instruction_tip {
        padding: 0
    }
}

@media screen and (max-width: breakpoints.$dsk) {
    .card_holder {
        display: none;
    }

    .info {
        display: none;
    }

    .instruction_tip {
        display: block;
    }
}

.pill {
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 20px;
    margin-right: 10px;
    background-color: #b0b0b0;
    color: #b0b0b0;
    transition: background-color 0.3s ease;
}

.pill.selected {
    background-color: #0D908E;
    color: #0D908E;
}