@use '../../assets/css-vars/breakpoints' as breakpoints;

.help_me {
    background: linear-gradient(180deg, rgba(27, 144, 142, 1) 0%, rgba(255, 255, 255, 1) 100%);
    font-family: 'Lora';
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    min-height: 190px;
    cursor: pointer;
    border: 1px solid gray;
    border-radius: 8px;
    margin-bottom: 10px;
    .question_symbol {
        font-size: 65px;
        color: #fff;
    }
    .help_me_text {
        font-size: 25px;
    }
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 130px !important;
    z-index: 1000;
    min-height: fit-content !important;
    padding: 9px;
    .question_symbol {
        font-size: 48px;
        line-height: 42px;
    }
    .help_me_text {
        font-size: 16px;
        font-style: italic;
        color: #000;
        font-weight: bold;
    }
}