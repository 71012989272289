@use '@/assets/css-vars/breakpoints' as breakpoints;

.menu {
    position: relative; 
    min-width: 100%; 
}

.header_menu_wrapper {
    position: absolute;
    top: 85%; 
    left: 10%; 
    width: 80%;
    //min-width: 90%; 
    //max-height: 40%; 
    overflow-y: auto; 
    overflow-x: hidden; 
    z-index: 1000; 
	background: #fff;  
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.7);
    border-radius: 5px;
	transition: all linear 0.3s;
	display: none;
}
.header_menu_wrapper.visible {
    display: block; 
}

.header_menu_wrapper_mobile{
    
    top: 100%; 
    left: 0; 
    max-width: 100%; 
    max-height: 40%; 
    overflow-y: auto; 
    overflow-x: hidden;
    z-index: 1000; 
	background: #fff;
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.7);
    border-radius: 5px;
	transition: all linear 0.3s;
	display: none;
}

.header_menu_wrapper_mobile.visible {
    display: block; 
}

.header_menu {
	//position: relative;
	display: flex;
	//align-items: end;
	justify-content: space-between;
	margin-top: 20px;
	width: 100%;
	height: 35px;
	margin-left: -15px;
    //border: 1px solid red;
}

.header_menu_left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.header_menu_right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.header_menu_item {
	position: relative;
	display: flex;
	align-items: end;
	justify-content: left;
	margin: 0px auto 0px auto;
	width: 100%;
    //border: 1px solid red;
}
.header_menu_separator {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header_menu_link {
    color: #000;
    text-transform: uppercase;
    text-decoration: none;
    margin: 0 18px;
    font-size: 14px;
    &:hover {
        border-bottom: 2px solid teal;
    }
}

.drawer_header {
	display: flex;
	flex-direction: column;
	min-width: 180px;
	color: #000 !important;
	text-transform: uppercase;
	a {
		color: #000 !important;
		padding: 5px 10px;
		font-size: 14px;
		//width: 100%;
	}
}

@media screen and (max-width: breakpoints.$dsk) {
    .header_menu {
        display: none;
    }
    .drawer_header {
        a[id^="MenuTitleLink"] {
            text-decoration: underline rgb(175, 233, 233) !important;
        }

    }
}
