.error-container {
    display: flex;
    flex-direction: column;
    max-width: "60%";
    align-items: center;
    border: 2px solid red;
    padding: 16px;
    margin-top: 20px;
    background-color: #f9f9f9;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }