@use '@/assets/css-vars/breakpoints' as breakpoints;

.marqueeSection {
    &Container {
        width: 100%;
        height: 500px;
        background: white;
        overflow: hidden;
        margin: 0;
        padding: 0;
        color: white;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    &Media {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &TextContainer {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        // want to make it transparent
        // background: rgb(125, 125, 125, 0.5)  !important;
        box-sizing: border-box;
        z-index: 1;
        padding-bottom: 20px;
    }

    &Text1 {
        font-size: 32px;
        margin-top: 100px;
        color: #000000 !important;

        span {
            font-style: italic;
        }
    }

    &Text3 {
        font-size: 26px;
        margin-top: 45px;
        color: #000000 !important;
        @media screen and (max-width: 640px) {
            font-size: 22px;
        }
        span {
            font-style: italic;
        }
    }

    &Text2 {
        font-size: 27px;
        font-family: 'Lora';
        font-weight: 300;
        margin-top: 30px;
        color: #000000 !important;
    }

    &Bottom {
        width: 100%;
        height: 100px;
        position: absolute;
        bottom: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

    }

    &Button {
        margin-top: 30px;
        color: white !important;
        font-weight: bold;
    }

    @media (max-width: 768px) { 

        &Container {
            height: 560px;
            background: #72767C;
            display: block;
            justify-content: center;
            align-items: center;
        }

        &TextContainer {
            width: 100%;
            height: 90px;
            margin: 0;
            padding: 0;
            display: grid;
            align-content: center;
            text-align: center;
            box-sizing: border-box;
            z-index: 1;
        }

        &Text1 {
            font-size: 24px;
            margin-top: 20px;
            text-shadow: 5px 5px 10px #000000;
        }
    
        &Text2 {
            font-size: 22px;
            margin-top: 10px;
            font-family: 'Lora';
            font-weight: 500;
        }

        &Media {
            height: 300px;
            width: 100%;
            overflow: hidden;
            position: absolute;
            top: 150px;
            left: 0;
            right: 0;
            bottom: 0;
        }

        &Bottom {
            width: 100%;
            height: 100px;
            position: absolute;
            bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.mainContainer{
    width: 100%;
    height: 100%;
}
.sectionTitle {
    text-align: center;
    font-size:32px;
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
}
.roomtypeButtons{
    width: 100%;
    height: 15px;
}
.categories {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: var(--padding-category);
    margin-bottom: var(--margin-bottom-category);
    flex: 0 1 auto;
  }
.category {
    text-align: center;
    white-space: nowrap;
    }

    .category button {
        border: 1px solid #606060;
        width: auto;
        flex: 1;
        padding: 5px;
        margin-right: 5px;
        border-radius: 8px;
        justify-content: space-around;
        color: #606060;
        background-color: transparent;
        cursor: pointer;
        text-transform: none;
        font-size: 16px;
        font-family: 'Nunito', serif;
        transition: border-color 0.3s ease;
    }

    .category button:hover, .category button:active, .category button.selected {
        border-color: #00a99d;
        color: #00a99d;
        background-color: transparent;
    }
    

.category img {
    border-radius: 50%;
    object-fit: cover;
    border: 4px solid transparent;
    transition: border-color 0.3s ease;
  }

  .designImages{
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    border-radius: 15px;
    
}
.designImages:hover {
    cursor: pointer;
    transform: scale(1.06);
    z-index: 10000;
    border-radius: 15px;
}
  
.category p {
    // margin-top: 10px;
    font-size: 16px;
    color: #4a4a4a;
  }
 
.category img:hover {
    border-color: #00a99d; /* Highlight border on hover */
 }

.category .imageWrapper:hover + p {
color: #00a99d;  
// font-size: 17px;
font-weight: 500;
}

.imageWrapper {
    position: relative;
    display: inline-block;
}

.category .overlayText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 14px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    pointer-events: none; /* Make sure this doesn't affect hover on the image */
  }

.imageWrapper .overlayText {
    pointer-events: none; /* Make sure this doesn't affect hover on the image */
}

.categoriesContainer {
    display : flex;
    justify-content: center;
    flex-direction: row; // Aligns items in a row
    flex-wrap: nowrap;   // Prevents wrapping
    overflow-x: auto;    // Allows horizontal scrolling if needed
    position: relative;
}

@media (max-width: 768px) {
    .categoriesContainer {
        background-color: #9cceca; /* Mobile container color */
        padding: 20px; /* Add some padding if needed */
        overflow-x: auto; /* Allow horizontal scrolling if necessary */
        overflow-y: auto;
        margin-top: 70px;
    }

    .mobileButtonContainer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-around; /* Align buttons in a single row with space around */
        background-color: rgba(231, 244, 244); /* White background */
        padding: 10px; /* Padding around the buttons */
        box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2); /* Optional shadow for visual separation */
        z-index: 1000; /* Ensure it is on top of other content */
        max-height: 30%;
        // margin-bottom: 3px;
      }
    
      .mobileButton {
        flex: 1; /* Allow buttons to grow equally */
        margin: 0 5px; /* Space between buttons */
        min-width: 0; /* Prevent overflow issues */
        border: 1px solid #b0b0b0;
        border-radius: 8px;
        justify-content: center;
        color: #606060;
        background-color: #ffffff;
        cursor: pointer;
        text-transform: none;
        font-size: 2.6vw;
        font-family: 'Nunito', serif;
        transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease; /* Ensure all properties transition */
        white-space: nowrap; /* Prevent text from wrapping to the next line */
        overflow: hidden; /* Hide overflow text */
        text-overflow: ellipsis; /* Show ellipsis for overflowing text */
        margin-top: 2px;
        margin-bottom: 3px;
      }
      
      /* Update the hover and active state */
      .mobileButton:hover {
        border-color: #00a99d; /* Change border color on hover */
        color: #00a99d; /* Change text color on hover */
        background-color: #ffffff; /* Change background color on hover */
      }

      .mobileButton:active {
        border-color: #00a99d; /* Change border color on hover */
        color: #00a99d; /* Change text color on hover */
        background-color: #ffffff; /* Change background color on hover */
      }

      .selected {
        border-color: #00a99d; /* Change border color on hover */
        color: #00a99d; /* Change text color on hover */
        background-color: #ffffff; /* Change background color on hover */
      }
}



.category.pending img {
    /* Disable hover styles for pending items */
    border-color: transparent !important;
    cursor: not-allowed; /* Change cursor to indicate no interaction */
    pointer-events: none; /* Disable all pointer events */
}

.category.pending p {
    color: #4a4a4a; /* Ensure text color does not change */
    font-size: 16px; /* Ensure the font size stays normal */
    pointer-events: none; /* Disable all pointer events on the p tag */
}

.category.pending:hover img,
.category.pending:hover p {
    /* Disable hover effects on pending items */
    color: #4a4a4a;
    font-size: 16px;
    border-color: transparent;
}

/* Optional: Disable onclick for pending items */
.category.pending {
    pointer-events: none;
}



.selectedRoomContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #eef7f7;
}
  
.selectedRoomContainer .header {
display: flex;
justify-content: center;
width: 100%;
padding: 10px;
}

.selectedRoomContainer h2 {
margin: 0;
font-size: 24px;
font-weight: bold;
}

.selectedRoomContainer a {
font-size: 14px;
color: #007bff;
text-decoration: none;
cursor: pointer;
}

.selectedRoomContainer a:hover {
text-decoration: underline;
}
  
.hr{
    height: 1px; /* Set height (thickness) of the line */
    background-color: #666666; /* Set line color */
    // width: 1223.88px; /* Set line width */
    margin: 0; /* Remove default margins */
    display: block; /* Ensure the div acts as a block element */
    margin-left: 0px;
    margin-right: 0px;
    // margin-left: 180px;
    // margin-right:180px;
    margin-bottom: 2px;
}

.hr2 {
    height: 1px; /* Thickness of the line */
    background-color: #666666; /* Line color */
    margin: 0; /* Vertical margins for spacing */
    width: 100%; /* Full width of the container */
    border: none; /* No default border */
    border-top: 1px solid #606060; /* Custom top border */
    display: block;
    margin-bottom: 2px;
}

@media (max-width: 768px) {
    .sectionTitle {
        text-align: center;
        font: lora;
        font-size:24px;
        margin-left: 20px;
        margin-bottom: 20px;
        margin-top: 20px;
    }
}

/*
.sectionDescription {
    text-align: center;
    font: lora;
    font-size:32px;
    padding-bottom: 20px;
}
*/

.roomsSection {
    margin-top: 80px;

    .title  {
       text-align: center;
       font-family: lora;
       font-size:42px;
    }

    .description {
        text-align: center;
        font-family: nunito;
        font-size:24px;
        padding-bottom: 20px;
    }

    .roomItem {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .roomLink {
        text-decoration: none;
    }

    .roomPaper {
        height: 200px;
        width: 350px;
        background-size: cover;
        border: 4px solid lightgray;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .roomTitle {
        color: white;
        font-size: 30px;
        background-color: rgba(211, 211, 211, 0.3);
        padding: 5px 10px; /* Add some padding around the text */
        border-radius: 10px; /* Rounded corners */
    }

    // Media query for mobile view
    @media (max-width: 768px) {
        .roomPaper {
            height: 100px;
            width: 150px;
            border: 2px solid lightgray;
        }

        .roomTitle {
            font-size: 20px;
        }
    }

    @media (max-width: 319px) {
        .roomPaper {
            height: 80px;
            width: 120px;
            border: 2px solid lightgray;
        }

        .roomTitle {
            font-size: 15px;
        }
    }
}
.bodycontainer{
    width: 100%;
    height: 1515px;
}
.bodycontent{
    width: 100%;
    height: 1419.93px;
    background-color: #72767C;

}

.styleCard {
    &Container {
        height: 600px;
        font-weight: bold;
        display: flex;
        
        position: relative;
        overflow: hidden;
        
        margin-right: 5px;
        border-right: 1px solid gray;
    }

    &Media {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &TextContainer {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        // want to make it transparent
        background: rgb(0, 37, 92, 0.35)  !important;
        box-sizing: border-box;
        z-index: 1;
    }

    &Text1 {
        font-size: 50px;
        color: white !important;

        span {
            font-style: italic;
        }
    }

    &Text2 {
        font-size: 40px;
        margin-top: 30px;
        line-height: normal;
        color: white !important;
    }

    &Bottom {
        width: 100%;
        height: 100px;
        position: absolute;
        bottom: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

    }

    &Button {
        font-weight: bold;
        text-align: center;
    }

    @media (max-width: 768px) { 

        &Container {
            height: 500px;
            border-right: '1px solid gray';
            
        }

        &TextContainer {
            width: 100%;
            height: 130px;
            margin: 0 auto;
            display: grid;
            align-content: center;
            text-align: center;
            box-sizing: border-box;
            z-index: 1;
            background: white ;
            background: rgb(0, 37, 92, 0.35)  !important;
            text-shadow: 5px 5px 10px #000000;
            
        }

        &Text1 {
            font-size: 24px;
            margin-top: 20px;
        }
    
        &Text2 {
            font-size: 18px;
            margin-top: 10px;
        }

        &Media {
            height: 250px;
            width: 100%;
            overflow: hidden;
            position: absolute;
            top: 130px;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgb(0, 37, 92, 0.35)  !important;
            z-index: 1;
        }

        &Bottom {
            width: 100%;
            height: 120px;
            position: absolute;
            bottom: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgb(0, 37, 92, 0.35)  !important;
        }
    }
}

.react-multiple-carousel__arrow {
    position: absolute;
    outline: 0;
    transition: all .5s;
    border-radius: 35px;
    z-index: 1000;
    border: 0;
    background: white;
    min-width: 43px;
    min-height: 43px;
    opacity: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &.react-multiple-carousel__arrow--right {
        right: 10px;
    }

    &.react-multiple-carousel__arrow--left {
        left: 10px;
    }

    svg {
        width: 1.5em;
        height: 1.5em;
        color: black;
    }
}

.carouselItem {
    padding: 10px 20px;
}

.carousel_wrapper {
    padding: 5px;
}
.cardsHoverEffect{
    border-width: 1px;
    border-radius: 4px;
    border-style: solid;
    border-color: rgba(160, 164, 171, 0.25);   
    border: none;
}

.selectAnotherRoom{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: right;
    padding-right: 20px;
    padding-top: 5px;
    text-decoration: underline !important;
    color: #000000;
    font-size: 14px;
    text-decoration: underline;
    text-decoration-style: solid; 
    text-decoration-color: #000000; 
}
.helpMeContainer {
    position: fixed;
    right: 0;
    top: 70%;
    transform: translateY(-50%);
}

.helpMeContainer  div {
    min-height: 0;
    width: 80%;
}

.container {
    display: flex;
    justify-content: center; /* Aligns the entire container to the center */
    flex-direction: row; /* Aligns the items in the container horizontally (side by side) */
}

.column {
    flex: 1; /* Each column takes up equal width */
    padding: 10px; /* Optional: Adds space inside the columns */
}

/* Optional: If you want to control the width of each column */
.column-1 {
    width: 50%; /* First column takes up 50% of the container */
}

.column-2 {
    width: 50%; /* Second column takes up 50% of the container */
}


@media (min-width: 374px) and (max-width: 500px) {
    .category p {
        margin-bottom: 20px !important; 
    }
}


// Mobile version
@media (max-width: 767px) {
    .imageWrapper{
        height: 104px;
    }

    .category p{
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 16px;
    color: #4a4a4a;
    }
    .marqueeSection{
        &TextContainer{
            height: 81px;
            padding: 0;
        }

    }

    

    .imageWrapper{
        height: 104px;
    }

    .category p{
    margin-top: 0;
    // margin-bottom: 20px;
    font-size: 16px;
    color: #4a4a4a;
    }

    .helpMe{
        position: fixed;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 88px !important;
        z-index: 1000;
        min-height: -moz-fit-content !important;
        min-height: fit-content !important;
        padding: 0px 20px 10px 20px;
        height: 83px;
    }
    .hero-image .button-panel {
        flex-direction: column;
    }
    
    .MuiImageList-root.MuiImageList-masonry.mui-style-10riir9-MuiImageList-root {
        column-count: 2;
      }
    .categories{
        width: 50% !important;
    }
    .selectedRoomContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        background-color: #eef7f7;
        text-align: center;
    }
      
    .selectedRoomContainer .header {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px;
    }
    
    .selectedRoomContainer h2 {
    margin: 0;
    font-size: 22px;
    font-weight: bold;
    }
}

// Desktop version
@media (min-width: 769px) {
    .hero-image .button-panel {
        flex-direction: row;
    }
}

.loading {
    align-items: center;
    justify-content: center;
    font-size: x-large;
    font-weight: bold;
    font-display: inherit;
}

.showAll {
    border: 1px solid #606060;
    max-width: 100px;
    max-height: 40px;
    flex: 1;
    padding: 5px;
    margin-right: 5px;
    border-radius: 8px;
    justify-content: space-around;
    color: #606060;
    background-color: transparent;
    cursor: pointer;
    text-transform: none;
    font-size: 16px;
    font-family: 'Nunito', serif;
    transition: border-color 0.3s ease;
    margin-top: 4px;
}

.showAll:hover {
    border-color: #00a99d;
    color: #00a99d;
    background-color: transparent;
}

.showAll:active {
    border-color: #00a99d;
    color: #00a99d;
    background-color: transparent;
}

.showAll button.selected {
    border-color: #00a99d;
    color: #00a99d;
    background-color: transparent;
}
.mobileButtonContainer {
    display: flex; /* Flexbox to arrange buttons in a row */
    flex-direction: row; /* Align buttons in a single row */
  }

  .loading {
    height: calc(100vh - 75px);
    display: flex;
    align-items: center;
    justify-content: center;
}